import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faPuzzle, faHandshakeAngle, faLightbulbOn, faFilePdf } from '@fortawesome/pro-thin-svg-icons'
import { MDBCol } from 'mdbreact'
import { CustomLink } from '../utils/helpers.js'
import Link from 'gatsby-link'


class Related extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    const type = this.props.type === 'Service' ? faHandshakeAngle : this.props.type === 'News' ? faNewspaper : this.props.type === 'Product' ? faPuzzle : this.props.type === 'Resource' ? faFilePdf : faLightbulbOn

    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-30 d-flex" >
        <div className="card promoting-card flex-fill card-body-grey card-hover">
          <div className="card-body d-flex flex-row" style={{ maxHeight: colHeight, minHeight: colHeight }}>
            <div>
              <p className="card-text">
              <FontAwesomeIcon icon={type} className="unicom-blue fa-3x" /></p>              
              <h3 className="font-alt font-w-700 mt-2 letter-spacing-1 title-small text-lg-extra-large title-xs-medium"> {this.props.title} </h3>
            </div>
          </div>

          <div className="card-body-prods">
            <hr />
            {this.props.link ? (
              <div className="card-text text-card-small text-medium pt-3 pb-4">
                <CustomLink link={this.props.link} class="effect">
                  {this.props.description}
                </CustomLink>
            </div>
            ) : (
              <div className="card-text text-card-small text-medium mb-5"></div>
            )}
          </div>
        </div>
      </MDBCol>
    )
  }
}

export default Related